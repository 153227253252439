import { Box, Grid, Typography } from '@mui/material';
import useTranslator from 'hooks/useTranslator';
import { format } from 'date-fns';
import { URLS } from 'helper';

const styles = {
  container: (theme) => ({
    width: '100vw',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    background: `transparent url(${URLS.BACKGROUND_IMAGE})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top center',
    [theme.breakpoints.down('sm')]: {
      py: 8,
      px: '10vw'
    }
  }),
  masthead: (theme) => ({
    my: '18px',
    lineHeight: '115px',
    textTransform: 'uppercase',
    [theme.breakpoints.down('sm')]: {
      lineHeight: '76px',
      fontSize: 60
    }
  }),
  mastheadSub: (theme) => ({
    textTransform: 'uppercase',
    [theme.breakpoints.down('md')]: {
      fontSize: 20
    }
  }),
  pkrLogo: {
    width: 128,
    height: 64
  },
  container2: (theme) => ({
    pt: '107px',
    pb: '122px',
    px: '29vw',
    [theme.breakpoints.down('md')]: {
      px: '10vw'
    }
  })
}

const Success = ({ acceptDate=new Date() }) => {
  const { t } = useTranslator();

  return (
    <Grid container>
      <Grid item xs={12} sx={styles.container}>
        <Box component={'img'} src={URLS.PKR} sx={styles.pkrLogo}/>
        <Typography variant={'masthead'} align={'center'} sx={styles.masthead}>
          {t('t:thankYou')}
        </Typography>
        <Typography variant={'mastheadSub'} align={'center'} sx={styles.mastheadSub}>
          {t('t:registered')}
        </Typography>
        <Box sx={{border: 2, borderColor: 'white', mt: 3, py: 1, px: 3}}>
          <Typography variant={'body2'} align={'center'} sx={{textTransform: 'uppercase'}}>
            {t('t:acceptDate')}: {format(acceptDate, 'hh:mm a, dd/MM/yyyy')}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
}

export default Success;