const palette = {
	// main colors
	primary: {
		main: '#01A0C6',
		contrastText: '#FFFFFF',
		dark: '#0284A3'
	},
	secondary: {
		main: '#FF0001',
	},
	
	// gray colors
	white: '#FFFFFF',
	black: '#000000',
	darkGrey: '#AAAAAA',
	lightGrey: '#D5D5D5',
	lighterGrey: '#EFEFEF'
};

export default palette;