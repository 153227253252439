const ml = {
  t: {
    // home page
    masthead: 'SUKARELAWAN KEADILAN',
    mastheadSub: 'MENGHADAPI MUSIM BANJIR',
    volunteerDesc: 'Segala bantuan tenaga dan sumbangan daripada anda akan membantu menyediakan makanan, tempat perlindungan dan sedikit kelegaan kepada mangsa banjir.\n\nDaftar sebagai sukarelawan KEADILAN dan menyumbang hari ini.',
    registerNow: 'DAFTAR SEKARANG!',
    personalInfo: 'Maklumat Peribadi',
    fullName: 'Nama Penuh',
    icNumber: 'No. Kad Pengenalan',
    address: 'Alamat',
    phoneNumber: 'No. Telefon',
    email: 'Emel',
    twitter: 'Twitter',
    facebook: 'Facebook',
    aid: 'Bantuan yang boleh ditawarkan',
    chooseFromList: '-- Pilih daripada senarai --',
    fieldVolunteer: 'Sukarelawan lapangan (bantu pemindahan, pembersihan, agih makanan)',
    monetary: 'Bantuan kewangan',
    foodPrep: 'Penyediaan makanan',
    transport: 'Pengangkutan',
    others: 'Lain-lain (nyatakan)',
    isInfoTrue: 'Maklumat yang tertera di atas adalah tepat',
    submit: 'Hantar',
    thankYou: 'Terima kasih',
    registered: 'Berdaftar sebagai sukarelawan',
    acceptDate: 'Tarikh Terima'
  },
  error: {
    
  }
};

export default ml;