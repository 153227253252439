import { object, string } from 'yup';

export const formInitialValues = {
  fullName: '',
  icNumber: '',
  phoneNumber: '',
  address: '',
  email: '',
  facebook: '',
  twitter: '',
  aid: '',
  aidOthers: '',
  isStatementTrue: false
};

export const formValidator = object({
  fullName: string().required('Sila isi nama penuh anda'),
  icNumber: string()
    .min(14, 'No. kad pengenalan tidak sah')
    .max(14, 'No. kad pengenalan tidak sah')
    .required(`Sila isi no. kad pengenalan anda`),
  phoneNumber: string().required('Sila isi no. telefon anda'),
  email: string().email('Emel tidak sah').required('Sila isi emel anda'),
  address: string().required('Sila isi alamat tetap anda'),
  facebook: string(),
  twitter: string(),
  aid: string().required('Sila pilih bantuan'),
  aidOthers: string().when('aid', {
    is: 'Lain-lain (nyatakan)',
    then: string().required('Sila nyatakan bantuan'),
  })
});

export const URLS = {
  BACKGROUND_IMAGE: 'https://ayuhmalaysia.s3.ap-southeast-1.amazonaws.com/sukarelawankeadilan/png/bg.png',
  FLOOD: 'https://ayuhmalaysia.s3.ap-southeast-1.amazonaws.com/sukarelawankeadilan/png/flood.png',
  LOGO: 'https://ayuhmalaysia.s3.ap-southeast-1.amazonaws.com/sukarelawankeadilan/png/logo.png',
  PKR: 'https://ayuhmalaysia.s3.ap-southeast-1.amazonaws.com/calonkeadilan/svg/pkr.svg'
};