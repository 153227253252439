const ENDPOINT = 'https://itcwu78qt3.execute-api.ap-southeast-1.amazonaws.com/dev';

export const register = async (values) => {
  const data = {
    "nama": values.fullName,
    "no_kp": values.icNumber,
    "no_tel": values.phoneNumber,
    "email": values.email,
    "alamat": values.address,
    "facebook": values.facebook,
    "twitter": values.twitter,
    "bantuan": values.aid,
    "bantuan_lain_lain": values.aidOthers
  };

  const res = await fetch(ENDPOINT + '/api/sukarelawan', {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
  .then((res) => {
    if (res.status !== 200 & res.status !== 201) {
      throw new Error(res.text() || 'Failed');
    }
    return res.json();
  })
  .then((resData) => {
    return resData;
  })
  .catch((err) => {
    console.log(err);
    throw new Error(err);
  });

  return res;
}
