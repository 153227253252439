import { Button as MuiButton, CircularProgress, Icon, Typography } from "@mui/material";

const Button = ({ sx, leftIcon=null, rightIcon=null, label='', loading=false, ...rest }) => {
  return (
    <MuiButton
      variant={'contained'}
      sx={{
        borderRadius: 0,
        padding: '15px 31px',
        display: 'flex',
        alignItems: 'center',
        boxShadow: 'none',
        ...loading && {pointerEvents: 'none'},
        ...sx
      }}
      {...rest}
    >
      {loading ?
      <CircularProgress
        color={'white'}
        sx={{
          width: '30px !important',
          height: '30px !important'
        }}
      />
      :
      <>
      {leftIcon ?
      <Icon sx={{fontSize: 25, mr: 1, color: 'white'}}>
        {leftIcon}
      </Icon> : null }
      <Typography variant={'button'}>
        {label}
      </Typography>
      {rightIcon ?
      <Icon sx={{fontSize: 25, ml: 1, color: 'white'}}>
        {rightIcon}
      </Icon> : null }
      </>}
    </MuiButton>
  );
}

export default Button;