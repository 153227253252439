import { Route, Routes } from 'react-router-dom';

import Layout from 'components/Layout';
import Home from 'views/Home';
import NoMatch from 'views/NoMatch';

const App = () => {
  return (
    <Routes>
      <Route element={<Layout/>}>
        <Route index element={<Home/>} />
      </Route>
      <Route path={'*'} element={<NoMatch/>} />
    </Routes>
  );
}

export default App;
