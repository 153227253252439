import { Box, Grid, Typography } from '@mui/material';
import Button from 'components/Button';
import { formInitialValues, formValidator, URLS } from 'helper';
import useTranslator from 'hooks/useTranslator';
import Checkbox from 'components/Checkbox';
import { useState } from 'react';
import Register from './Register';
import { useFormik } from 'formik';
import Success from './Success';
import { register } from 'api';

const styles = {
  container: (theme) => ({
    width: '100vw',
    height: '100vh',
    minHeight: 740,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    background: `transparent url(${URLS.BACKGROUND_IMAGE})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top center',
    px: '20vw',
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
      minHeight: '100vh',
      py: 8,
      px: '10vw'
    }
  }),
  pkrLogo: {
    width: 128,
    height: 64
  },
  masthead: (theme) => ({
    my: '18px',
    lineHeight: 1,
    [theme.breakpoints.down('md')]: {
      fontSize: 60
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 35
    }
  }),
  mastheadSub: (theme) => ({
    textTransform: 'uppercase',
    [theme.breakpoints.down('md')]: {
      fontSize: 30
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 20
    }
  }),
  container2: (theme) => ({
    pt: '107px',
    pb: '122px',
    px: '29vw',
    [theme.breakpoints.down('md')]: {
      px: '10vw'
    }
  }),
  title: (theme) => ({
    textTransform: 'uppercase',
    [theme.breakpoints.down('sm')]: {
      fontSize: 28
    }
  }),
  floodImgContainer: (theme) => ({
    width: '100%',
    px: '16vw',
    [theme.breakpoints.down('sm')]: {
      px: '5vw'
    }
  }),
  floodImg: (theme) => ({
    width: '100%',
  }),
  formContainer: (theme) => ({
    px: '21vw',
    pb: '120px',
    pt: '100px',
    backgroundColor: 'white',
    [theme.breakpoints.down('md')]: {
      px: '10vw'
    }
  }),
  btnContainer: (theme) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
      pt: 3
    }
  }),
  footer: (theme) => ({
    backgroundColor: 'primary.main',
    height: 540,
    width: '100%',
    py: '74px',
    px: '320px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      px: '10vw'
    }
  }),
  pkrLogoSmall: {
    width: 86,
    height: 43,
    boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.3)',
  }
}

const Home = () => {
  const { t } = useTranslator();

  const [isSubmitting, setSubmitting] = useState(false);
  const [success, setSuccess] = useState(false);
  const [submittedAt, setSubmittedAt] = useState(null);

  const { values, setFieldValue, errors, handleSubmit } = useFormik({
    initialValues: {
      ...formInitialValues
    },
    validationSchema: formValidator,
    validateOnChange: false,
    onSubmit: (values) => onSubmitForm(values)
  });

  const onSubmitForm = async (values) => {
    setSubmitting(true);

    // register
    const res = await register(values);
    if(res.status === 200 && res.data) {
      setSubmittedAt(new Date(res.data.created_at));
      setSuccess(true);
      
    }
    setSubmitting(false);
  }

  return (
    !success ?
    <Grid container>
      <Grid item xs={12} sx={styles.container}>
        <Box component={'img'} src={URLS.PKR} sx={styles.pkrLogo}/>
        <Typography variant={'masthead'} align={'center'} sx={styles.masthead}>
          {t('t:masthead')}
        </Typography>
        <Typography variant={'mastheadSub'} align={'center'} sx={styles.mastheadSub}>
          {t('t:mastheadSub')}
        </Typography>
      </Grid>

      <Grid item xs={12} sx={styles.container2}>
        <Box sx={{mb: '58px'}}>
          <Typography color={'primary.main'} variant={'title'} sx={styles.title}>
            {t('t:masthead')}
          </Typography>
        </Box>
        <Box>
          <Typography color={'black'} sx={{whiteSpace: 'pre-wrap'}}>
            {t('t:volunteerDesc')}
          </Typography>
        </Box>
      </Grid>

      <Grid item xs={12} sx={styles.floodImgContainer}>
        <Box component={'img'} src={URLS.FLOOD} sx={styles.floodImg}/>
      </Grid>

      <Grid container item xs={12} sx={styles.formContainer}>
        <Grid item xs={12}>
          <Typography variant={'title'} color={'primary.main'} sx={styles.masthead}>
            {t('t:registerNow')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Register
            values={values}
            setFieldValue={(n,v) => setFieldValue(n,v)}
            errors={errors}
          />
        </Grid>
        <Grid container item xs={12} sx={{pt: 15, alignItems: 'center'}}>
          <Grid item xs={12} sm={7}>
            <Box sx={{display: 'flex', alignItems: 'center'}}>
              <Checkbox
                checked={values.isStatementTrue}
                onChange={(e) => setFieldValue('isStatementTrue', e.target.checked)}
              />
              <Typography variant={'caption'} color={'black'} lineHeight={'25px'}>
                {t('t:isInfoTrue')}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={5} sx={styles.btnContainer}>
            <Button
              label={t('t:submit')}
              loading={isSubmitting}
              sx={{
                backgroundColor: 'secondary.main',
                '&:hover': {
                  backgroundColor: 'secondary.main'
                }
              }}
              onClick={() => handleSubmit()}
              disabled={!values.isStatementTrue}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} sx={styles.footer}>
        <Box component={'img'} src={URLS.PKR} sx={styles.pkrLogoSmall}/>
        <Box>
          <Typography variant={'footerText'} fontFamily={'AGE'} lineHeight={1} sx={styles.title}>PARTI</Typography><br/>
          <Typography variant={'footerText'} lineHeight={1} sx={styles.title}>KEADILAN</Typography><br/>
          <Typography variant={'footerText'} fontFamily={'AGE'} lineHeight={1} sx={styles.title}>RAKYAT</Typography>
          <Typography variant={'caption'}><br/><br/>PAKATAN HARAPAN</Typography>
        </Box>
      </Grid>
    </Grid>
    :
    <Success acceptDate={submittedAt}/>
  );
}

export default Home;