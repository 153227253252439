import { Grid, MenuItem, Typography } from '@mui/material';
import TextField from 'components/TextField';
import useTranslator from 'hooks/useTranslator';
import { useEffect } from 'react';
import InputMask from 'react-input-mask';

const styles = {
  formSection: {
    pt: '76px',
  },
  formField: {
    py: 1,
    px: 1
  },
};

const Register = ({ values, setFieldValue, errors }) => {
  const { t } = useTranslator();

  useEffect(() => {
    const _errors = Object.keys(errors);
    const elementScroll = document.querySelector(`[name="${_errors[0]}"]`);
    if (elementScroll instanceof HTMLElement) elementScroll.scrollIntoView({ block: 'start', behavior: 'smooth' });
  }, [errors])

  return (
    <Grid container item xs={12} sx={styles.formSection}>
      <Grid item xs={12} sx={styles.formField}>
        <Typography variant={'titleSmall'} color={'black'} textTransform={'uppercase'}>
          {t('t:personalInfo')}
        </Typography>
      </Grid>
      <Grid item xs={12} sx={styles.formField}>
        <TextField
          name={'fullName'}
          placeholder={t('t:fullName')}
          value={values.fullName}
          error={errors.fullName}
          helperText={errors.fullName || ''}
          onChange={(e) => setFieldValue('fullName', e.target.value)}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={7} sx={styles.formField}>
        <InputMask
          mask={'999999-99-9999'}
          maskChar={''}
          value={values.icNumber}
          onChange={(e) => setFieldValue('icNumber', e.target.value)}
        >
          {(inputProps) => (
            <TextField
              {...inputProps}
              name={'icNumber'}
              placeholder={t('t:icNumber')}
              error={errors.icNumber}
              helperText={errors.icNumber || ''}
              fullWidth
            />
          )}
        </InputMask>
      </Grid>
      <Grid item xs={12} sm={5} sx={styles.formField}>
        <InputMask
          mask={'999-99999999'}
          maskChar={''}
          value={values.phoneNumber}
          onChange={(e) => setFieldValue('phoneNumber', e.target.value)}
        >
          {(inputProps) => (
            <TextField
              {...inputProps}
              name={'phoneNumber'}
              placeholder={t('t:phoneNumber')}
              error={errors.phoneNumber}
              helperText={errors.phoneNumber || ''}
              fullWidth
            />
          )}
        </InputMask>
      </Grid>
      <Grid item xs={12} sm={7} sx={styles.formField}>
        <TextField
          name={'email'}
          placeholder={t('t:email')}
          value={values.email}
          error={errors.email}
          helperText={errors.email || ''}
          onChange={(e) => setFieldValue('email', e.target.value)}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sx={styles.formField}>
        <TextField
          name={'address'}
          placeholder={t('t:address')}
          value={values.address}
          error={errors.address}
          helperText={errors.address || ''}
          onChange={(e) => setFieldValue('address', e.target.value)}
          fullWidth
          multiline
          minRows={4}
        />
      </Grid>
      <Grid item xs={12} sm={6} sx={styles.formField}>
        <TextField
          name={'facebook'}
          placeholder={t('t:facebook')}
          value={values.facebook}
          error={errors.facebook}
          helperText={errors.facebook || ''}
          onChange={(e) => setFieldValue('facebook', e.target.value)}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6} sx={styles.formField}>
        <TextField
          name={'twitter'}
          placeholder={t('t:twitter')}
          value={values.twitter}
          error={errors.twitter}
          helperText={errors.twitter || ''}
          onChange={(e) => setFieldValue('twitter', e.target.value)}
          fullWidth
        />
      </Grid>
      <Grid container item xs={12} sx={{...styles.formField, alignItems: 'center', pt: 8}}>
        <Grid item xs={12} md={5}>
          <Typography color={'black'} variant={'body2'}>- {t('t:aid')}</Typography>
        </Grid>
        <Grid item xs={12} md={7}>
          <TextField
            name={'aid'}
            value={values.aid || t('t:chooseFromList')}
            error={errors.aid}
            helperText={errors.aid || ''}
            onChange={(e) => setFieldValue('aid', e.target.value)}
            fullWidth
            select
          >
            <MenuItem value={t('t:chooseFromList')}>
              <Typography variant={'body2'} color={'darkGrey'}>{t('t:chooseFromList')}</Typography>
            </MenuItem>
            {['fieldVolunteer', 'monetary', 'foodPrep', 'transport', 'others'].map((o,i) => (
              <MenuItem value={t(`t:${o}`)} sx={{whiteSpace: 'pre-wrap'}}>
                <Typography variant={'body2'} color={'black'}>{t(`t:${o}`)}</Typography>
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        { values.aid === 'Lain-lain (nyatakan)' ?
        <Grid container item xs={12} sx={{pt: 2}}>
          <Grid item xs={5}/>
          <Grid item xs={12} md={7}>
            <TextField
              name={'aidOthers'}
              placeholder={t('t:others')}
              value={values.aidOthers}
              error={errors.aidOthers}
              helperText={errors.aidOthers || ''}
              onChange={(e) => setFieldValue('aidOthers', e.target.value)}
              fullWidth
            />
          </Grid>
        </Grid>
        : null }
      </Grid>

    </Grid>
  );
}

export default Register;